@use "../styles/theme.scss";

:local(.chat-input-container) {
  padding: 8px 16px;
  background-color: theme.$input-bg-color;
  flex-basis: max-content;
}

:local(.message-list) {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  list-style: none;
  padding: 0 16px;
  overflow-y: auto;
  min-height: 0;
}

:local(.message-group) {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  padding-top: 16px;

  &:last-child {
    padding-bottom: 8px;
  }
}

:local(.message-group-label) {
  color: theme.$text2-color;
  font-size: theme.$font-size-xs;
  display: flex;
  margin: 2px;
}

:local(.message-group-messages) {
  display: flex;
  flex-direction: column;
}

:local(.message-row) {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

:local(.message-bubble) {
  background-color: theme.$chat-bubble-bg-color-received;
  border-radius: 19px;
  margin: 2px;
  padding: 10px 16px;
  max-width: 80%;
  width: max-content;
  font-size: theme.$font-size-md;
  overflow-wrap: break-word;
  line-height: 1.25;

  img,
  video {
    max-height: 240px;
    border-radius: 18px;
  }

  a {
    text-decoration: underline;
  }
}

:local(.sent) {
  :local(.message-group-label) {
    align-self: flex-end;
  }

  :local(.message-row) {
    flex-direction: row-reverse;
    justify-content: end;
  }

  :local(.message-bubble) {
    background-color: theme.$chat-bubble-bg-color-sent;
    color: theme.$chat-bubble-text-color-sent;
    align-self: flex-end;

    a {
      color: theme.$chat-bubble-text-color-sent;

      &:hover {
        color: theme.$chat-bubble-link-color-sent-hover;
      }

      &:active {
        color: theme.$chat-bubble-link-color-sent-pressed;
      }
    }
  }
}

:local(.message-bubble):local(.emoji),
:local(.message-bubble):local(.media) {
  font-size: 32px;
  background-color: theme.$transparent;
  padding: 0;
}

:local(.message-bubble):local(.monospace) {
  font-family: monospace;
}

:local(.system-message) :local(.message-group-label) {
  display: inline;
  vertical-align: bottom;

  i {
    font-size: theme.$font-size-xs;
    color: theme.$text2-color;
  }

  span {
    margin-left: 1ch;
  }
}

:local(.message-bubble):local(.permission) {
  display: flex;
  gap: 10px;
  background-color: theme.$transparent;
  border: 1px solid theme.$input-border-color;
  color: theme.$text1-color;
  align-items: center;
}

:local(.chat-input-icon) {
  cursor: pointer;
  width: 24px;

  &:disabled,
  &[disabled] {
    cursor: default;
  }

  svg {
    color: theme.$input-icon-color;
  }
}

:local(.icon-button) {
  width: 48px;
  height: 48px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 18px;
  background-color: transparent;

  &:hover {
    @media (pointer: fine) {
      color: theme.$primary-color-hover;
    }
  }
}

:local(.chat-input-warning) {
  padding-top: theme.$spacing-2xs;
}

:local(.warning-border) {
  border: 2px solid theme.$red !important;
}

:local(.warning-text-color) {
  color: theme.$red;
}

:local(.chat-input-text-area-styles) {
  max-height: 50vh;
}

:local(.emojiPopover) {
  border: none;
}
